<template>
  <section>
    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title></b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div>

            <!-- Card Actions -->
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>

          <!-- Card Body  -->
          <b-card-body>
            <b-row>
              <b-col>
                <b-list-group>
                  <b-list-group-item><b>{{ $t("Id") }}:</b>
                    {{ this.invocation_id }}</b-list-group-item>
                  <b-list-group-item><b>{{ $t("Name") }}:</b>
                    {{ this.invocation_name }}</b-list-group-item>
                  <b-list-group-item><b>{{ $t("Created At") }}:</b>
                    {{ this.invocation_created_at }}</b-list-group-item>

                  <b-list-group-item><b>{{ $t("Tipo Function Objetivo") }}:</b>
                    {{
                      this.invocation_tipo_funcion_objetivo
                    }}</b-list-group-item>

                  <b-list-group-item><b>{{ $t("Tipo Ajuste") }}:</b>
                    {{ this.invocation_tipo_ajuste }}</b-list-group-item>

                  <b-list-group-item><b>{{ $t("Status") }}:</b>
                    {{ this.invocation_status }}</b-list-group-item>

                  <b-list-group-item><b>{{ $t("Generación Actual") }}:</b>
                    {{ this.invocation_current_generation }}</b-list-group-item>

                  <!--
                  <b-list-group-item
                    ><b>{{ $t("Tiempo Restante") }}:</b>
                    {{
                      this.invocation_tiempo_restante_human
                    }}</b-list-group-item
                  >
                                  -->
                </b-list-group>
              </b-col>

              <b-col>
                <b-form-tags v-model="tag_names" :placeholder="$t('Add tags...')" @input="tagInput"></b-form-tags>
              </b-col>

              <b-col>
                <b-form-checkbox v-model="liveReload" name="check-button" @change="liveReloadChange" button
                  button-variant="outline-info">
                  <feather-icon icon="RefreshCwIcon" :badge="updatesCount" badge-classes="badge-info badge-glow"
                    size="15" />
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card-body>

          <template #footer>
            <b-progress height="5px" variant="info" striped :animated="true" :value="generation"
              :max="max_generation"></b-progress>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button variant="flat-secondary" class="btn-icon float-right" v-b-toggle.sidebar-1>
          <feather-icon icon="HelpCircleIcon" size="20" />
        </b-button>
      </b-col>

      <b-sidebar id="sidebar-1" title="Resultado de Optimizaciones" shadow bg-variant="dark" text-variant="light"
        width="450px" right backdrop>
        <div class="px-2 py-2"></div>
      </b-sidebar>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title>Detalle de Mezclas</b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div>

            <!-- Card Actions -->
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>

          <!-- Card Body  -->
          <b-card-body>
            <b-button class="mb-2" size="sm" @click="compareRuns()" :disabled="disableCompareRuns"
              variant="primary">Comparar</b-button>
            <ag-grid-vue style="width: 100%; height: 500px" class="ag-theme-alpine" :columnDefs="columnDefs"
              :rowData="rowData" :pinnedTopRowData="pinnedTopRows" :rowSelection="rowSelection"
              @cellValueChanged="onCellValueChanged" @selectionChanged="onSelectionChanged" @grid-ready="onGridReady"
              :rowMultiSelectWithClick="true">

            </ag-grid-vue>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <ag-grid-table style="width: 100%" ref="optimization-comments-table" :debug="this.$route.query.debug == '1'"
          :configUrl="commentsConfig" :dataUrl="commentsData">
          <template v-slot:footer>
            <div style="margin-top: 2rem">
              <b-input-group>
                <b-form-input v-model="nuevo_comentario" :placeholder="$t('Ingresar nuevo comentario...')"></b-form-input>
                <b-input-group-append>
                  <b-button @click="nuevoComentario" variant="outline-primary"
                    :disabled="nuevo_comentario.trim().length <= 0">{{ $t("Guardar Comentario") }}</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </template>
        </ag-grid-table>

        <div>
          <b-button id="no-utilizadas" class="mb-2" variant="primary" @click="exNoUtilizadas()">
            Existencias no utilizadas <b-icon icon="question-circle"></b-icon>
          </b-button>
          <b-button :to="{ name: 'molymet-sugerencia-mezcla' }"
            style="height: 50px; background-color: #053743 !important; float:right; margin-right: 20px; padding-top: 17px;">
            Volver a Sugerencias de Mezcla
          </b-button>
          <b-tooltip target="no-utilizadas" triggers="hover">
            Existencias no utilizadas en la &uacute;ltima sugerencia de mezclas, es
            decir, todo el stock disponible en base a la última simulación.
          </b-tooltip>
        </div>


      </b-col>
    </b-row>
  </section>
</template> 
        
<script>

class StateCellRenderer {
  init(params) {
    this.eGui = document.createElement('div');
    if (params.value) {
      this.eGui.innerHTML = `${params.value}`;     
    } else {
      this.eGui.innerHTML = 'Indefinido';
    }
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}


import CardTooltip from '@/views/brunacomponents/wrappers/CardTooltipWrapper.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import Stat from '@/views/clients/base/components/Stat.vue'
import Stats from './Stats.vue';
import moment from 'moment';
import HeaderCellRenderer from '@/views/brunacomponents/ag-grid-table/cell-renderers/HeaderCellRenderer.vue';
import { AgGridVue } from "ag-grid-vue";
import axios from '@axios'
import ActionsCellRenderer3 from "@/views/brunacomponents/ag-grid-table/cell-renderers/ActionsCellRender3.vue";
import {
  BTabs,
  BTab,
  BOverlay,
  BSpinner,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormTags,
  BFormCheckboxGroup,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BProgress
} from "bootstrap-vue";
var qs = require('qs');
import useApiServices from '@/services/useApiServices.js';


export default {

  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    BrunaElementLoader,
    AgGridTable,
    AgGridVue,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    Stat,
    BFormTags,
    BProgress,
    BListGroup,
    BListGroupItem,
    headerCellRenderer: HeaderCellRenderer,
    CardTooltip,
    Stats,
    BFormCheckboxGroup,
    BFormCheckbox,
    customActionsCellRender3: ActionsCellRenderer3,
  },
  data() {
    let optimization_id = this.$route.query.optimization_id;
    let query = qs.stringify({
      optimization_id: optimization_id
    })

    return {
      gridApi: null,
      showOverlay: false,
      liveReload: false,
      updatesCount: '',
      columnDefs: [],
      rowData: [],
      pinnedTopRows: [],
      commentsConfig: useApiServices.invocations.comments.historico.config + "?" + query,
      commentsData: useApiServices.invocations.comments.historico.data + "?" + query,
      defaultColDef: {
        headerValueGetter: (header) => { return this.$t(header.column.userProvidedColDef.headerName) },
      },
      stats: [],
      stats2: [],
      stats3: [],
      stats_8horas: [],
      stats2_8horas: [],
      stats3_8horas: [],
      tag_names: [],
      nuevo_comentario: "",
      invocation_id: "",
      invocation_name: "",
      invocation_created_at: "",
      invocation_status: "",
      invocation_tipo_funcion_objetivo: "",
      invocation_tipo_ajuste: "",
      invocation_current_generation: '',
      invocation_tiempo_restante_human: '',
      generation: 0,
      max_generation: 100,
      refreshTime: new Date().getTime(),

      rowSelection: null,
      isRowSelectable: null,
      disableCompareRuns: true,
      selectedRows: [],
      editUrl: useApiServices.detalleOptimizacion.editDetalleOptimizacion + "/" + this.$route.query.optimization_id
    };
  },


  beforeUnmount() {
    this.$pusher.unsubscribe('optimizations');
  },

  beforeRouteLeave(to, from, next) {
    this.$pusher.unsubscribe('optimizations');
    next()
  },

  mounted() {

    let optimization_id = this.$route.query.optimization_id;
    this.refreshData(optimization_id);
    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher

    var channel = this.$pusher.subscribe('optimizations');

    channel.bind('OptimizationUpdateEvent', function (data) {

      let userData = JSON.parse(localStorage.getItem("userData"));

      if (userData && userData.client.id == data.client_id) {
        let invocation_id = data.invocation_id
        if (optimization_id == invocation_id) {
          if (!self.liveReload) {
            if (self.updatesCount == '') {
              self.updatesCount = 1;
            }
            else {
              self.updatesCount += 1;
            }
          }
          else {
            let toastId = data.content.id
            data.content.component = ToastificationContent;
            self.$toast.dismiss(toastId);
            self.$toast(data.content, { id: toastId });
          }
        }
        if (optimization_id == invocation_id && self.liveReload) {
          self.refreshData(optimization_id);
        }
      }
    });
  },

  created() {


    let optimization_id = this.$route.query.optimization_id;
    useApiServices.getInvocationTags(optimization_id)
      .then((response) => {
        this.tag_names = response.data.map((tag) => tag.name)
      })
  },

  methods: {

    updateTablaMezcla(optimization_id) {
      let refreshTime = new Date().getTime()
      let query = qs.stringify({
        optimization_id: optimization_id,
        ts: refreshTime
      })

      axios.request({
        method: "get",
        url: useApiServices.detalleOptimizacion.detalleOptimizacionMezclasTable + "?" + query,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          let columnDefs = response.data.columnDefs;

          // console.log(columnDefs);

          columnDefs.push(
            {
              flex: 1,
              field: 'Mezcla_Utilizada',
              headerName: 'Mezcla Utilizada',
              width: 200,
              // cellEditor: 'agSelectCellEditor',
              cellEditor: 'agRichSelectCellEditor',

              cellEditorPopup: true,
              cellRenderer: StateCellRenderer,
              keyCreator: (params) => {
                return params.value;
              },
              cellEditorParams: {
                cellRenderer: StateCellRenderer,
                values: ['Utilizada', 'No Utilizada']
              },
              editable: true,
              pinned: "right",
              cellStyle: { border: 'none' }
            },
            {
              flex: 1,
              cellEditor: null,
              cellEditorParams: [],
              cellEditorPopup: false,
              cellRenderer: 'customActionsCellRender3',
              cellRendererParams: {
                actions: [
                  { name: "Ver Detalles", icon: "EyeIcon", action: this.accionVerDetalles },
                ]
              },
              editable: false,
              field: "",
              headerCheckboxSelection: false,
              headerName: this.$t("Acciones"),
              headerTooltip: "Tooltip",
              initialSort: null,
              menuTabs: [],
              pinned: "right",
              sortable: false,
              cellStyle: { border: 'none' }
            },
          )

          this.columnDefs = columnDefs;
          this.rowData = response.data.rowData;
          this.pinnedTopRows = response.data.pinnedTopRows;

          this.rowSelection = 'multiple';

        })
    },

    accionVerDetalles(data) {

      console.log(data)
      // return
      let optimization_id = this.$route.query.optimization_id;
      let mezcla_id = data.data.Mezcla;

      this.$router.push({
        name: 'molymet-detalle-optimizacion-mezcla', query: {
          optimization_id: optimization_id,
          mezcla_id: mezcla_id
        }
      })
    },

    liveReloadChange(checked) {

      if (checked) {
        this.updatesCount = ''

        let optimization_id = this.$route.query.optimization_id;
        this.refreshData(optimization_id);
      }
    },

    refreshData(optimization_id) {
      this.getInvocationDetails(optimization_id)
      this.updateTablaMezcla(optimization_id)
    },

    getInvocationDetails(invocation_id) {
      useApiServices.getInvocationDetails(invocation_id)
        .then((response) => {

          let invocation_details = response.data
          this.invocation_id = invocation_details.id
          this.invocation_name = invocation_details.name
          this.invocation_created_at = localDateFormatter(invocation_details.created_at)
          this.invocation_status = invocation_details.status
          this.invocation_tipo_funcion_objetivo = invocation_details.tipo_funcion_objetivo
          this.invocation_tipo_ajuste = invocation_details.tipo_de_solucion
          this.generation = invocation_details.generation
          this.max_generation = invocation_details.max_generation
          this.invocation_current_generation = this.generation + " de " + this.max_generation

          let invocation_tiempo_restante = invocation_details.invocation_tiempo_restante
          var duration = moment.duration(invocation_tiempo_restante * 1000);
          this.invocation_tiempo_restante_human = duration.humanize()
        })
    },

    elementLoaded(event) {
      console.log("elementLoaded2", event)
    },

    nuevoComentario() {
      let invocation_id = this.$route.query.optimization_id;

      useApiServices.postInvocationCreateComment(invocation_id, { body: this.nuevo_comentario })
        .then((response) => {
          this.$refs['optimization-comments-table'].refreshRows([], false)
          this.nuevo_comentario = ""
        })
        .catch((error) => {

        });
    },

    tagInput(value) {
      let data = {
        tags: value
      }

      let optimization_id = this.$route.query.optimization_id;

      useApiServices.postInvocationAddTags(optimization_id, data)
        .then((response) => {
          console.log(response);
        })
    },

    compareRuns() {
      // console.log();
      let optimization_id = this.$route.query.optimization_id;
      let mezclas = this.selectedRows.map(run => run.Mezcla);

      this.$router.push({ name: 'molymet-sugerencia-mezcla-comparar', query: { mezclas: mezclas, optimization_id: optimization_id } })
    },

    exNoUtilizadas() {
      // this.$router.push({ name: 'molymet-detalle-optimizacion', query: { optimization_id: id } })

      // console.log(this.rowData);
      let mezclas = []
      this.rowData.forEach(data => {
        if (data.Mezcla_Utilizada == "No Utilizada") {
          mezclas.push(data.Mezcla)
        }
      });
      // console.log(mezclas);
      this.$router.push({ name: 'molymet-existencias-no-utilizadas', query: { mezclas: mezclas, optimization_id: this.$route.query.optimization_id } })
    },

    onSelectionChanged() {
      // console.log(this.gridApi.getSelectedNodes())
      this.selectedRows = this.gridApi.getSelectedRows();
      this.disableCompareRuns = (this.selectedRows.length < 2)
      this.disableRellenar = !(this.selectedRows.length == 1)

      this.$emit('selectionChanged', this.selectedRows);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      if (this.rowModelType == "serverSide") {
        const createServerSideDatasource = () => {
          return {
            getRows: this.getRows,
          };
        };

        const updateData = () => {
          // create datasource with a reference to the fake server
          var datasource = createServerSideDatasource();
          // register the datasource with the grid
          params.api.setServerSideDatasource(datasource);
        };

        updateData();

      }
      this.$emit('gridReady', this.gridApi, this.gridColumnApi, params);
    },

    onCellValueChanged(params) {
      // console.log("onCellValueChanged");
      // console.log(params);
      // console.log(this.rowData);
      this.editData(params);
    },

    editData(params) {
      // console.log('EDIT => ', params);
      // console.log('ROW => ', this.rowData);
      return axios.request({
        method: "post",
        url: this.editUrl,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: this.rowData,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
        
<style >
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}

.icon-plan {
  padding-top: 5%;
}

.ag-row-pinned {
  background-color: #f0e68c !important;
}
</style>
        
      
<style>
div.ag-watermark {
  display: none !important;
}
</style>
      